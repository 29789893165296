<template>
  <div>
    <div>
      <div class="info-person cursorP" v-if="items">
        <ul>
          <li class="out cursorP">
              <div class="out-main" @click="goRouter('/public/resume/', items.id, 2)">
                <div class="profile">
                  <div class="outside">
                    <img :src="items.imageurl" alt class="err_avatar" />
                  </div>
                </div>
                <div class="info-text">
                  <div class="flex tex">
                    <h4 class="line_clamp1">{{ items.userName }}</h4>
                    <span>{{ items.sex }}</span>
                    <!-- <p class="far">远程面试</p> -->
                  </div>

                  <div class="out-1">
                    <p>
                      <span v-if="items.age">{{ items.age }}岁</span>
                      <span v-else>年龄暂无</span>
                    </p>
                    <p v-if="items.edu">
                      <span>{{ items.edu }}</span>
                    </p>
                    <p v-if="items.edu === null || items.edu.length == 0">
                      <span>无学历</span>
                    </p>
                    <p v-if="items.exp">
                      <span>{{ items.exp }}</span>
                    </p>
                    <p v-else>
                      <span>暂无工作年限</span>
                    </p>
                  </div>
                  <div class="out-1" v-if="items.disabledType">
                    <p>
                      <span v-if="items.disabledType[0]"
                      >残疾类别：{{
                          items.disabledType[0].disabledType
                        }}</span
                      >
                      <span v-else>残疾类别：暂无</span>
                    </p>
                    <p>
                      <span v-if="items.disabledType[0]">{{
                          items.disabledType[0].disabledLevel
                        }}</span>
                      <span v-else>暂无残疾等级</span>
                    </p>
                  </div>
                  <div class="out-1" v-else>
                    <p>
                      <span>残疾类别：暂无信息</span>
                    </p>
                  </div>
                </div>
              </div>
          </li>
          <li class="out">
            <div
                class="out-flex"
                v-for="(item, index) in items.resumeExpect || []"
                :key="index"
            >
              <p class="salary" v-if="item.salary">
                期望薪资：{{ item.salary }}
              </p>
              <p class="salary" v-else>期望薪资：暂无</p>
              <p class="line_clamp1" v-if="item.jobNext">
                <span>求职意向：</span>
                {{ item.jobNext }}
              </p>
              <p class="line_clamp1" v-else>
                <span>求职意向：</span>
                暂无
              </p>
              <p>期望地点：{{ item.cityid }}</p>
            </div>
            <div
                class="out-flex"
                v-if="
                items.resumeExpect === null || items.resumeExpect.length == 0
              "
            >
              <p class="salary">期望薪资：暂无</p>
              <p class="line_clamp1">求职意向：暂无</p>
              <p>期望地点：暂无</p>
            </div>
          </li>
          <li class="out">
            <div right-s>
              <p class="post">简历更新时间：{{ items.updateTime }}</p>
              <div class="info-right">
                <p
                    class="look cursorP"
                    @click="goRouter('/public/resume/', items.id, 2)"
                >
                  <a>看简历</a>
                </p>
                <p class="look" @click="invatie(item.memberId,item.id)">
                  <a>邀面试</a>
                </p>
              </div>
            </div>
          </li>
        </ul>
        <div
            class="display-none"
            @click="collect(items.memberId, items.collectionType)"
        >
          <div class="star">
            <span
                :class="items.collectionType == 1 ? 'iscollect' : 'uncollect'"
            ></span>
            <span>{{ items.collectionType == 1 ? "已收藏" : "收藏" }}</span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-dialog
          title="面试邀请"
          width="40%"
          :visible.sync="dialogFormVisible"
          class="bg"
      >
        <Invative
            @close="close"
            :invativeId="invativeId"
            :jobInfo="jobInfo"
        ></Invative>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Editor from "../public/editor";
import Scascader from "../dialog/scascader";
import Zhucescascader from "../dialog/zhucescascader";
import Invative from "../enterprise/Invative";
export default {
  components: { Editor, Scascader, Zhucescascader, Invative },
  props: {
    item: Object,
  },
  data() {
    return {
      loading: false,
	  memberIds: [],
      items: this.item,
      underline: false,
      invativeId: "",
      dialogFormVisible: false,
      comIndoLlist: [],
      jobInfo: {},
	  id:""	
    };
  },
  methods: {
    async getComInfo() {
      let that = this;
      let res = await that.$api.comCenterBasicInfo();
      if (res.data.code === 200) {
        that.comIndoLlist = res.data.data;
      }
    },
    collect(memberId, collectionType) {
      if (collectionType == 1) {
        this.items.collectionType = 0;
      } else {
        this.items.collectionType = 1;
      }
      this.docollect(memberId);
      //item.collectionType
      // this.iscollect = !this.iscollect;
    },
    // 邀面试
    invatie(memberId,id) {
      if (this.comIndoLlist.auditStatus != 2) {
        this.$message.warning("当前企业资质审核非通过状态，不可邀请面试！");
        return;
      } else {
        let a = localStorage.getItem("time");
        let b = localStorage.getItem("id");
        // if (a && this.items.memberId == b) {
        //   this.$message.warning("您已邀请过该残疾人");
        //   return;
        // }
        this.memberId = memberId;
        this.$store.commit("SET_INVATIVE_ID", this.memberId);
        this.jobInfo = {
          memberId: memberId,
		  iddd:id
        };
		this.jobInfo.iddd = id;
        this.dialogFormVisible = true;
      }
    },
    // 收藏
    async docollect(memberId) {
      let params = {
        diabledId: memberId,
      };

      let res = await this.$api.collectResume(params);
      let msg = "已成功收藏";
      if (this.items.collectionType == 0) {
        msg = "已取消收藏";
      }
      if (res.data.success) {
        this.$message({
          showClose: true,
          message: msg,
          type: "success",
        });
      }
    },
    // 不合适
    noFit() {
      this.$message({
        showClose: true,
        message: "操作成功，该简历已被标记为不合适",
        type: "success",
      });
    },
    // 关闭
    close() {
      this.dialogFormVisible = false;
    },
    goRouter(url, memberId, userType) {
		console.log(this.items);
		// this.memberIds.push(memberId);
		// let params = {
		//   jobId: this.items.jobObject.id,
		//   memberIds: this.memberIds,
		//   id:this.items.id,
		//   applyStatus: "2",
		// };
		// this.$api.applyFacing(params);
      this.$api.addCompanyLookResumeApi();
      const { href } = this.$router.resolve({
        path: url,
        query: {
          id: memberId,
          userType: userType,
        },
      });
      window.open(href, "_blank");
    },
  },
  created() {
    let data = JSON.parse(localStorage.getItem('basicInfo'));
    if (data){
      this.comIndoLlist = data;
    }
  },
};
</script>

<style lang="less" scoped>
.bg {
  top: -80px;
}
/deep/.el-form-item__content {
  margin-left: 0;
}
.autos {
  display: flex;
  margin-left: 0;
  justify-content: center;
  .el-button {
    margin-left: 0;
  }
}
/deeep/ .el-dialog__title {
  color: orange;
}

/deep/ .el-input__inner {
  height: 34px;
}
.info-person {
  position: relative;
  max-width: 860px;
  // 不合格
  .img-s {
    position: absolute;
    right: 15px;
    top: 30px;
    width: 50px;
    display: block;
  }
  height: 106px;
  border: 1px solid #e2e2e2;
  display: flex;
  align-items: center;
  margin: 0 12px;
  margin-bottom: 10px;

  ul {
    flex: 1;
    box-sizing: border-box;
    padding-left: 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .out {
      float: left;
      height: 91px;
    }
    .out-flex {
      display: flex;
      // flex-direction: column;
      justify-content: left;
      flex-wrap: wrap;
      align-items: center;
    }
    li:nth-child(1) {
      flex: 2.5;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      // padding: 0 10px;
      .out-main {
        display: flex;

        .profile {
          width: 50px;
          margin-right: 20px;

          .outside {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #dfdfdf;

            img {
              width: 100%;
              height: 100%;
              overflow: hidden;
              border-radius: 50%;
            }
          }
        }

        .info-text {
          // flex: 1;
          width: 220px;
          display: flex;
          flex-direction: column;

          .tex {
            width: 50%;
            justify-content: space-between;
            h4 {
              font-size: 16px;
              font-weight: 600;
              color: #00924c;
              line-height: 22px;
              margin-bottom: 10px;
              max-width: 300px;
              cursor: pointer;
            }
            span {
              color: #333333;
              font-weight: 600;
              margin-left: 10px;
            }
            h4:hover {
              color: #00924c;
              cursor: pointer;
            }
          }

          .out-1 {
            display: flex;
            margin-left: -7px;

            p {
              border-right: 1px solid #999999;
              height: 12px;
              padding-right: 5px;
              display: flex;
              align-items: center;
              line-height: 12px;
              margin-bottom: 10px;
              span {
                font-size: 12px;
                color: #999999;
                text-align: center;
                line-height: 17px;
                padding-left: 6px;
              }
            }
            p:last-child {
              border-right: none;
            }
            i {
              font-size: 12px;
              font-style: normal;
              display: inline-block;
              margin-left: 5px;
            }
            p {
              width: 90;
            }
          }
        }
      }
    }

    li:nth-child(2) {
      flex: 2;
      // width: 260px;
      border-left: 1px dotted #e1e1e1;
      border-right: 1px dotted #e1e1e1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0 20px;
      padding-left: 35px;
      align-items: center;
      box-sizing: border-box;

      p {
        font-size: 12px;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
        margin-bottom: 3px;
        margin-right: 12px;
      }
      .salary {
        color: #ff5500;
      }
    }

    li:nth-child(3) {
      flex: 3.5;
      justify-content: center;
      flex-direction: column;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      .post {
        font-size: 12px;
        font-weight: 400;
        color: #333333;
        // line-height: 24px;
      }

      .info-right {
        margin-right: 30px;
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;

        .look {
          margin-top: 10px;
          a {
            font-size: 12px;
            font-weight: 500;
            color: #00924c;
            cursor: pointer;
            margin-right: 25px;
            text-decoration: underline;
          }
        }
      }
      .look:hover {
        color: #00924c;
      }
    }
  }

  .display-none {
    opacity: 0;
    width: 90px;
    height: 102px;
    background-color: #e5faef;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    flex-direction: column;

    .star {
      display: flex;
      justify-content: center;
      align-items: center;
      // margin-bottom: 22px;
      cursor: pointer;

      .iscollect {
        width: 19px;
        margin-right: 5px;
        display: inline-block;
        height: 18px;
        background: url("~static/resumes/collected.png") center center no-repeat;
        background-size: 100% 18px;
      }
      .uncollect {
        width: 19px;
        margin-right: 5px;

        display: inline-block;
        height: 18px;
        background-image: url("~static/resumes/collect.png");
        background-size: 100% 18px;
      }

      span:nth-child(2) {
        content: 收藏;
        color: #00924c;
        // width: 32px;
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
      }
    }

    .load {
      display: flex;
      justify-content: center;
      align-items: center;

      span:nth-child(1) {
        content: 下载;
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-right: 5px;
        background: url("../../static/resumes/load.png") no-repeat;
        background-size: 18px 18px;
      }

      span:nth-child(2) {
        color: #00924c;
        width: 32px;
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}
.info-person:hover .display-none {
  opacity: 1;
  transition: all 0.4s;
}
.info-person:hover {
  border: 1px solid #00924c;
  border-radius: 2px;
}
.far {
  background: #ff7029;
  color: #ffffff;
  padding: 0 3px;
}
</style>
