<template>
  <div class="centre-wrapper">
    <div>
      <div class="box">
        <div class="imgs-box">
          <div class="imgs">
            <img
                :src="comIndoLlist.logo || ''"
                alt
                :title="comIndoLlist.companyName"
                class="err_logo line_clamp1"
            />
          </div>
        </div>
        <div class="text-imgs">
          <div class="tops">
            <h2>{{ comIndoLlist.companyName }}</h2>
            <div class="auto">
              <p class="p2">
                {{
                  comIndoLlist.auditStatus | getStatus(comIndoLlist.auditStatus)
                }}
              </p>
            </div>
          </div>
          <div v-if="comIndoLlist.auditStatus === '3'" class="auditMsg">
            原因：{{ comIndoLlist.auditMsg }}
          </div>

          <div class="centers">
            <ul v-if="comIndoLlist.hyTop">
              <li>
                <p>{{ comIndoLlist.hyTop || 暂无 }}</p>
              </li>
            </ul>
            <h5>
              {{ comIndoLlist.pr }}
              <span>{{ comIndoLlist.mun }}</span>
            </h5>
          </div>
          <div class="bottoms">
            <p class="p1">{{ comIndoLlist.address }}</p>
            <router-link
                to="/enterprise/centre/infomanage/"
                rel="nofollow"
                title="更多企业信息"
            >
              <p class="p2">
                更多企业信息
                <i class="el-icon-caret-right"></i>
              </p>
            </router-link>
          </div>
        </div>
        <!-- <div class="qr">
          <img src="~/static/enterprise/qr.png" alt="残疾人大学生就业情况调查问卷">
          <p>残疾人大学生就业情况调查问卷</p>
        </div> -->
      </div>
      <div class="divider"></div>
      <div class="census">
        <div class="logo"></div>
        <p>近三个月的数据统计</p>
      </div>
      <div class="post-info">
        <ul>
          <li>
            <router-link to="/enterprise/post" target="_blank" class="p">
              {{ comIndoLlist.jobCounts || 0 }}
              <span>个</span>
            </router-link>
            <h4>在招职位</h4>
          </li>
          <li>
            <router-link
                to="/enterprise/recruiting?ids=third"
                target="_blank"
                class="p"
            >
              {{ comIndoLlist.sees || 0 }}
              <span>次</span>
            </router-link>
            <h4>被查看</h4>
          </li>
          <li>
            <router-link to="/enterprise/resumes" target="_blank" class="p">
              {{ comIndoLlist.receives || 0 }}
              <span>份</span>
            </router-link>
            <h4>收到简历</h4>
          </li>
          <li>
            <router-link
                to="/enterprise/resumes?ids=31"
                target="_blank"
                class="p"
            >
              {{ comIndoLlist.interviews || 0 }}
              <span>个</span>
            </router-link>
            <h4>面试邀请</h4>
          </li>
          <li>
            <router-link
                to="/enterprise/recruiting?ids=second"
                target="_blank"
                class="p"
            >
              {{ comIndoLlist.recommands || 0 }}
              <span>个</span>
            </router-link>
            <h4>推荐给我的</h4>
          </li>
        </ul>
        <router-link
            to="/enterprise/publish"
            target="_blank"
            title="点击跳转至发布职位"
        >
          <div class="publish">
            <h3>发布职位</h3>
            <p>去发布>></p>
          </div>
        </router-link>
      </div>
    </div>
    <div class="divider"></div>
    <div class="matching">
      <h3>简历匹配</h3>
      <h4>共匹配到 8 份简历</h4>
      <router-link
          :to="{ name: 'recruiting', query: { key: '/enterprise/recruiting ' } }"
          title="去筛选更多的简历吧！"
      >
        <p>去筛选更多简历吧！</p>
      </router-link>
    </div>
    <div class="main loads" v-loading="loading" element-loading-text="加载中..">
      <div class="forin" v-for="item in getResumesList" :key="item.memberId">
        <Info :item="item" :status="comIndoLlist.auditStatus"></Info>
      </div>
    </div>
  </div>
</template>

<script>
import Info from "components/enterprise/Info";

export default {
  components: {Info},
  data() {
    return {
      active: "1",
      comIndoLlist: {},
      getResumesList: [],
      resumes: {
        pageNum: 1,
        pageSize: 8,
        jobId: "",
      },
    };
  },
  filters: {
    getStatus(key) {
      let auditStatus = "";
      switch (key) {
        case "0":
          auditStatus = "信息待完善";
          break;
        case "1":
          auditStatus = "待审核";
          break;
        case "2":
          auditStatus = "审核通过";
          break;
        case "3":
          auditStatus = "审核不通过";
          break;
      }
      return auditStatus;
    },
  },
  methods: {
    async getComInfo() {
      let that = this;
      let res = await that.$api.comCenterBasicInfo();
      if (res.data.code === 200) {
        that.comIndoLlist = res.data.data;
      }
    },
    // 找人才数据请求
    async getResumes() {
      let that = this;
      this.loading = true;
      let res = await that.$api.getList(that.resumes);
      this.loading = false;
      if (res.data.success == false){
        this.$message.error('请求异常，请联系后台！');
        return
      }
      let data = this.$decrypt(res.data);
      this.getResumesList = data.data.records;
      this.total = res.data.data.total;
    },

  },
  mounted() {
    this.getComInfo();
  },
  created() {
    this.getResumes();
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/enterprise/centre.less";
.box{
  position: relative;
  .qr{
    position: absolute;
    top: 0px;
    right: 0;
    text-align: center;
    img{
      display: block;
      width: 90px;
      margin: 0 auto;
    }
    p{
      padding-top: 5px;
    }
  }
}
</style>
